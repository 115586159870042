import React from 'react'
import './Home.sass'
import Hero from '../../components/Hero/Hero'
import About from '../../components/About/About'
import Doctors from '../../components/Doctors/Doctors'
import RequestConsultation from '../../components/RequestConsultation/RequestConsultation'
import MainServices from '../../components/MainServices/MainServices'
import FeedsCarousel from '../../components/FeedsCarousel/FeedsCarousel'
import Partners from '../../components/Partners/Partners'
import News from '../../components/News/News'

function Home() {
  return (
    <div className="Home">
      <Hero />
      <About />
      <Doctors />
      <RequestConsultation />
      <MainServices />
      <FeedsCarousel />
      <Partners />
      <News />
    </div>
  )
}

export default Home
