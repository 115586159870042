import React, { useContext, useEffect, useRef } from 'react'
import './Nav.sass'
import Hamburger from 'react-hamburger-menu'
import Media from 'react-media'
import { withRouter } from 'react-router-dom'
import Logo from '../../ui/Logo/Logo'
import SiteNavLink from './SiteNavLink'
import { usePopUp } from '../../hooks/usePopUp'
import useScrollPosition from '../../hooks/useScrollPosition'
import { AuthContext } from '../../App'
import location from '../../locale/location'
import PhoneLinks from '../Top/PhoneLinks/PhoneLinks'
import SocialLinks from '../Top/SocialLinks/SocialLinks'
import DropdownMenu from './DropdownMenu/DropdownMenu'

function Nav({ user, ...router }) {
  const hamRef = useRef()
  const navRef = useRef()
  const [menuIsOpen, setMenuIsOpen] = usePopUp(hamRef, navRef)
  const pos = useScrollPosition()
  const { lang } = useContext(AuthContext)

  useEffect(() => {
    setMenuIsOpen(false)
  }, [router.location.pathname, router.location.hash])

  return (
    <nav
      id="nav"
      className={[
        'Site-Nav',
        ...(pos > 0 ? ['Site-Nav_theme_shadow'] : []),
      ].join(' ')}
    >
      <div className="Nav-Container DefaultContainer">
        <Logo navLink path="/#home" />
        <Media
          query="(max-width: 1200px)"
          render={() => (
            <div className="Top-Container MobileSocial">
              <PhoneLinks />
              <SocialLinks />
            </div>
          )}
        />

        <div
          ref={navRef}
          className="Site-Nav-Menu"
          style={menuIsOpen ? { display: 'grid' } : {}}
        >
          <SiteNavLink
            title="Лазерне лікування"
            path="/laser-vision-correction"
          />
          <SiteNavLink title="Прайс" path="/services" />
          <SiteNavLink title="Акції" path="/akcii" />

          <SiteNavLink title="Колектив" path="/doctors" />
          <SiteNavLink title="Оптика" path="/about-optics" />
          <DropdownMenu
            title="Академія"
            content={
              <>
                <SiteNavLink
                  title="Наукова діяльність"
                  path="/scientific-activity"
                />
                <SiteNavLink
                  title="Освітні програми"
                  path="/osvitni-programi"
                />
                <SiteNavLink title="ЗМІ" path="/mass-media" />
              </>
            }
          />
          <SiteNavLink title="Блог" path="/#novyny" />
          <SiteNavLink title="Контакти" path="/contacts" />
        </div>
        <Media
          query="(max-width: 1200px)"
          render={() => (
            <span ref={hamRef}>
              <Hamburger
                isOpen={menuIsOpen}
                width={24}
                height={16}
                strokeWidth={2}
                className="Site-Hamburger-Container"
                color="#303030"
                borderRadius={2}
                animationDuration={0.2}
              />
            </span>
          )}
        />
      </div>
    </nav>
  )
}

// const mapStateToProps = (state) => ({ user: state.auth.user })

export default withRouter(Nav)
