import React, { useEffect, useState } from 'react'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import RequestConsultation from '../../components/RequestConsultation/RequestConsultation'
import FeedsCarousel from '../../components/FeedsCarousel/FeedsCarousel'
import './TextPage.sass'
import getDoc from '../../utils/db/getDoc'
import { isEmpty } from 'lodash'
import parse from 'html-react-parser'
import ReactPlayer from 'react-player'
import bgImage from './consultPattern.svg'

function TextPage({ docId }) {
  const [data, setData] = useState(null)
  useEffect(() => {
    getDoc({
      path: 'pages',
      docId,
    }).then((res) => setData(res))
  }, [])

  return (
    <>
      <div className="TextPage" style={{ backgroundImage: `url(${bgImage})` }}>
        <div className="TextPage-Container DefaultContainer">
          {!isEmpty(data) && (
            <>
              <SectionTitle title={data.title} />
              {!isEmpty(data.video) && (
                <div className="TextPageVideo">
                  <ReactPlayer
                    url={data.video.publicUrl}
                    width="100%"
                    height="100%"
                    controls
                  />
                </div>
              )}

              <div class="TextPage-Text">{parse(data.text)}</div>
            </>
          )}
        </div>
      </div>
      <RequestConsultation />
      <FeedsCarousel />
    </>
  )
}

export default TextPage
