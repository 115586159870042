import React, { useEffect, useState } from 'react'
import Icon from '../../ui/Icon/Icon'
import './FeedsCarousel.sass'
import FeedTile from './FeedTile/FeedTile'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import Button from '../../ui/Button/Button'
import { isEmpty, orderBy } from 'lodash'
import Carousel from '../../ui/Carousel/Carousel'
import getCollection from '../../utils/db/getCollection'

function FeedsCarousel({ className }) {
  const [feeds, setFeeds] = useState(null)

  useEffect(() => {
    getCollection({
      path: 'feeds',
      docIdName: 'feedId',
    }).then((docs) => setFeeds(orderBy(docs, ['created'], ['desc'])))
  }, [])

  return (
    <div id="feeds" className={`FeedsCarousel ${className}`}>
      <div className="FeedsCarousel-Container DefaultContainer">
        <SectionTitle title="Що говорять про нас пацієнти?" />
        <div className="RatingButtons">
          <a
            href="https://maps.app.goo.gl/vkzw5EW3WP54JPN48?g_st=it"
            target="blank"
            className="RatingButton Google"
          >
            <Icon name="google" weight="brand" />
            <span className="Caption-Medium">Рейтинг:</span>
            <span className="Caption-Regular">4.8</span>
          </a>
          <a
            href="https://www.facebook.com/klinikazoru.pavliv"
            target="blank"
            className="RatingButton Facebook"
          >
            <Icon name="facebook-f" weight="brand" />
            <span className="Caption-Medium">Рейтинг:</span>
            <span className="Caption-Regular">5.0</span>
          </a>
        </div>
        <div className="FeedsCarousel-Feeds">
          <div className="FeedContainer">
            {!isEmpty(feeds) && (
              <Carousel
                element="Feeds-Carousel"
                options={{
                  type: 'carousel',
                  gap: 24,
                  perView: 4,
                  hoverpause: true,
                  // autoplay: 5000,
                  useDots: true,
                  useArrows: true,
                  breakpoints: {
                    1200: { perView: 3 },
                    992: { perView: 2 },
                    576: { perView: 1 },
                  },
                }}
              >
                {feeds.map((f) => (
                  <FeedTile key={f.feedId} {...f} />
                ))}
              </Carousel>
            )}
          </div>

          {/* <div className="FeedBack-Block">
            <p className="Title">Вже були нашим пацієнтом?</p>
            <p className="Description">
              Нам важливо знати, що Ви думаєте про якість надання наших послуг.
              Будемо вдячні за відгук
            </p>
            <div className="FeedBack-Buttons">
              <Button
                theme="primary-white"
                title="Залишити відгук"
                className="PrimaryWhite"
              />
              <Button
                theme="secondary-white"
                title="Більше відгуків"
                className="SecondaryWhite"
              />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default FeedsCarousel
