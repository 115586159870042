import React, { useEffect, useState } from 'react'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import getDoc from '../../utils/db/getDoc'
import Spinner from '../../ui/Spinner/Spinner'
import { isEmpty } from 'lodash'
import parse from 'html-react-parser'
import './Actions.sass'

function Actions() {
  const [data, setData] = useState(null)

  useEffect(() => {
    getDoc({ path: 'slider', docId: 'homeSlider' }).then((res) => setData(res))
  }, [])

  return (
    <div className="Actions">
      <div className="Actions-Container DefaultContainer">
        <SectionTitle title="Акції" />
        <div className="Actions-Container">
          {!isEmpty(data) ? (
            data.actions.map((a, i) => (
              <ActionTile num={i} {...a} photos={data.photos} />
            ))
          ) : (
            <Spinner type="module" />
          )}
        </div>
      </div>
    </div>
  )
}

function ActionTile({ num, photos, text }) {
  return (
    <div className="ActionTile">
      <img src={photos[num].publicUrl} alt="" />
      <div className="Text">{parse(text)}</div>
    </div>
  )
}

export default Actions
