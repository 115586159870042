import React from 'react'
import './Logo.sass'
import { NavHashLink } from 'react-router-hash-link'
import logo from './newlogo.svg'
import scrollWithOffset from '../ScrollToTop/scrollWithOffset'

function Logo({ navLink = false, path }) {
  return navLink ? (
    <NavHashLink
      to={path}
      className="Logo"
      scroll={(el) => scrollWithOffset(el, -64)}
    >
      <img src={logo} alt="Клініка зору Павлів" />
    </NavHashLink>
  ) : (
    <a href={path} className="Logo">
      <img src={logo} alt="Клініка зору Павлів" />
    </a>
  )
}

export default Logo
