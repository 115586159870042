import React from 'react'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import { isEmpty, orderBy } from 'lodash'
import { useState } from 'react'
import { useEffect } from 'react'
import getCollection from '../../utils/db/getCollection'
import parse from 'html-react-parser'
import './Scientific.sass'
import ReactPlayer from 'react-player'

function Scientific({ path, title }) {
  const [science, setScience] = useState(null)

  useEffect(() => {
    getCollection({
      path,
      docIdName: 'scienceId',
    }).then((docs) => setScience(orderBy(docs, ['created'], ['asc'])))
  }, [])

  return (
    <div className="Scientific">
      <div className="DefaultContainer">
        <SectionTitle title={title} />
        <div class="Scientific-Container">
          {!isEmpty(science) &&
            science.map((d) => <ScienceTile key={d.scienceId} {...d} />)}
        </div>
      </div>
    </div>
  )
}

function ScienceTile({ video, text }) {
  return (
    <div className="ScienceTile">
      <div className="Video">
        {video && (
          <ReactPlayer url={video} width="100%" height="100%" controls />
        )}
      </div>
      <div className="Content">
        <div className="ShortDesc">{parse(text)}</div>
      </div>
    </div>
  )
}

export default Scientific
