import React, { useEffect, useState } from 'react'
import getCollection from '../../../utils/db/getCollection'
import { isEmpty, orderBy } from 'lodash'
import { NewsTile } from '../News'
import Spinner from '../../../ui/Spinner/Spinner'
import './NewsArchive.sass'
import SectionTitle from '../../../ui/SectionTitle/SectionTitle'
import Button from '../../../ui/Button/Button'

function NewsArchive() {
  const [news, setNews] = useState(null)

  useEffect(() => {
    getCollection({ path: 'news', docIdName: 'newsId' }).then((data) =>
      setNews(
        orderBy(
          data.filter((d) => !d.isDeleted && d.status === 'published'),
          ['created'],
          ['desc']
        )
      )
    )
  }, [])

  return (
    <div className="NewsArchive">
      <div className="DefaultContainer">
        <div className="NewsArchive-Container">
          <SectionTitle title="Архів новин" />
          {!isEmpty(news) ? (
            news.map((n) => <NewsTile type={'DefaultType'} {...n} />)
          ) : (
            <Spinner type="module" />
          )}
          <Button
            theme="primary-white"
            title="Повернутись на головну"
            type="navlink"
            path="/#novyny"
            // onClick={() => {
            //   // window.scrollTo({ top: 0, behavior: 'smooth' })
            //   setShowContactUs({
            //     show: true,
            //     title: 'Задати питання',
            //     emailSubject:
            //       '[Питання] Новий запит від користувача сайту',
            //   })
            // }}
          />
        </div>
      </div>
    </div>
  )
}

export default NewsArchive
