import React from 'react'
import { useContext } from 'react'
import { AuthContext } from '../../App'
import Button from '../../ui/Button/Button'
import ContactUsPopUp from '../ContactUs/ContactUsPopUp'
import './Top.sass'
import { NavLink } from 'react-router-dom'
import SocialLinks from './SocialLinks/SocialLinks'
import PhoneLinks from './PhoneLinks/PhoneLinks'

function Top() {
  const { showContactUs, setShowContactUs } = useContext(AuthContext)

  return (
    <>
      {showContactUs.show && <ContactUsPopUp />}
      <div className="Top">
        <div className="Top-Container DefaultContainer">
          <PhoneLinks />
          <SocialLinks />
        </div>
      </div>
    </>
  )
}

export default Top
