import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import Button from '../../ui/Button/Button'
import getDoc from '../../utils/db/getDoc'

import './NewsPage.sass'
import Spinner from '../../ui/Spinner/Spinner'
import { find, isEmpty } from 'lodash'
import { formatDateAsDayMontYearHHMM } from '../../utils/date/dateFormat'
import parse from 'html-react-parser'

function NewsPage({ ...router }) {
  const newsId = router.match.params.id
  const [newsData, setNewsData] = useState(null)

  useState(() => {
    if (newsId) {
      getDoc({ path: 'news', docId: newsId, docIdName: 'newsId' }).then(
        (result) => {
          if (result) setNewsData(result)
        }
      )
    }
  }, [newsId])

  return (
    <div className="NewsPage">
      <div className="DefaultContainer">
        <div className="NewsPage-Container">
          {!isEmpty(newsData) ? (
            <>
              <p className="Date Caption-Medium">
                {formatDateAsDayMontYearHHMM(newsData.created)}
              </p>
              <p className="Title">{newsData.title}</p>
              {find(newsData.photos, ['isAvatar', true]) && (
                <img
                  className="NewsPage-Avatar"
                  src={find(newsData.photos, ['isAvatar', true]).publicUrl}
                  alt={newsData.title}
                />
              )}

              <p>{parse(newsData.longNews)}</p>
            </>
          ) : (
            <Spinner type="module" />
          )}
        </div>
        <Button
          theme="primary-white"
          title="Всі новини"
          type="navlink"
          icon="arrow-right"
          iconPosition="right"
          path="/#novyny"
        />
      </div>
    </div>
  )
}

export default withRouter(NewsPage)
