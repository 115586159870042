import React from 'react'
import Icon from '../../../ui/Icon/Icon'
import './PhoneLinks.sass'
import { useContext } from 'react'
import { AuthContext } from '../../../App'

function PhoneLinks() {
  const { contacts } = useContext(AuthContext)
  return (
    <div className="PhoneLinks">
      <a href={`tel: ${contacts.phone}`} className="PhoneIcon">
        <Icon name="phone" weight="solid" />
      </a>
      <a href={`tel: ${contacts.phone}`} className="PhoneNumber ">
        {contacts.phone}
      </a>
      <a href="viber://chat?number=%2B380988833880" target="blank">
        <Icon name="viber" weight="brand" />
      </a>
      <a href="https://t.me/+380988833880" target="blank">
        <Icon name="telegram" weight="brand" />
      </a>
      <a
        href="https://api.whatsapp.com/send/?phone=380988833880"
        target="blank"
      >
        <Icon name="whatsapp" weight="brand" />
      </a>
    </div>
  )
}

export default PhoneLinks
