import React from 'react'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import './Doctors.sass'
import { useState } from 'react'
import { useEffect } from 'react'
import getCollection from '../../utils/db/getCollection'
import { find, isEmpty, orderBy, toNumber } from 'lodash'
import Button from '../../ui/Button/Button'
import PopUp from '../../ui/PopUp/PopUp'
import parse from 'html-react-parser'
import RequestConsultation from '../RequestConsultation/RequestConsultation'

function Doctors() {
  const [doctors, setDoctors] = useState(null)

  useEffect(() => {
    getCollection({
      path: 'doctors',
      docIdName: 'doctorId',
      whereQueries: [{ fieldPath: 'isDeleted', op: '!=', value: true }],
    }).then((docs) => setDoctors(orderBy(docs, ['pos'], ['asc'])))
  }, [])

  return (
    <>
      <section id="doctors" name="doctors" class="Doctors">
        <div className="DefaultContainer">
          <SectionTitle title="Наш колектив" />
          <div class="Doctors-Container">
            {!isEmpty(doctors) &&
              doctors.map((d) => <DoctorTile key={d.doctorId} {...d} />)}
          </div>
        </div>
      </section>
    </>
  )
}

function DoctorTile({
  title,
  photos,
  descriptionShort,
  descriptionLong,
  startingYear,
}) {
  const avatar = find(photos, ['isAvatar', true])
    ? find(photos, ['isAvatar', true]).publicUrl
    : null
  const stage = new Date().getFullYear() - toNumber(startingYear)
  const [showPopUp, setShowPopUp] = useState(false)

  return (
    <div className="DoctorTile">
      <PopUp show={showPopUp} title={title} close={() => setShowPopUp(false)}>
        <div className="DescriptionContent">
          {parse(descriptionLong)}
          <Button
            className="CloseDoctorDescription"
            theme="primary-white"
            title="Закрити"
            type="button"
            onClick={() => setShowPopUp(false)}
          />
        </div>
      </PopUp>
      <div className="Avatar">
        <img src={avatar} alt={title} />
        <div className="DetailButton">
          <Button
            theme="primary-white"
            title="Детальніше"
            type="button"
            onClick={() => setShowPopUp(true)}
          />
        </div>
      </div>
      <div className="Content">
        <div className="Title">{title}</div>
        <div className="ShortDesc">{descriptionShort}</div>
        <div className="Stage">
          Стаж: {stage} {trueUkDecline('років', 'рік', 'роки', stage)}
        </div>
      </div>
    </div>
  )
}

function trueUkDecline(d1, d2, d3, c) {
  let res = ''
  switch (c % 100) {
    case 11:
    case 12:
    case 13:
    case 14:
      return d1
    default:
      switch (c % 10) {
        case 0:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
          return d1
        case 1:
          return d2
        case 2:
        case 3:
        case 4:
          return d3
      }
  }
  return ''
}

export default Doctors
