import React from 'react'
import './Hero.sass'
import { NavLink, withRouter } from 'react-router-dom'
import parse from 'html-react-parser'
import { NavHashLink } from 'react-router-hash-link'
import Icon from '../../ui/Icon/Icon'
import hero1 from './assets/1.jpg'
import scrollWithOffset from '../../ui/ScrollToTop/scrollWithOffset'
import Button from '../../ui/Button/Button'
import location from '../../locale/location'
import { useContext } from 'react'
import { AuthContext } from '../../App'
import { find, isEmpty } from 'lodash'
import { useState } from 'react'
import { useEffect } from 'react'
import getDoc from '../../utils/db/getDoc'
import Carousel from '../../ui/Carousel/Carousel'
import Media from 'react-media'

function Hero() {
  const [data, setData] = useState(null)
  useEffect(() => {
    getDoc({
      path: 'slider',
      docId: 'homeSlider',
    }).then((res) => setData(res))
  }, [])

  return (
    <div id="home" className="Site-Hero">
      <div className="SiteHero-Container DefaultContainer">
        <Media
          query="(min-width: 769px)"
          render={() =>
            !isEmpty(data) && (
              <Carousel
                element="Hero-Carousel"
                options={{
                  type: 'carousel',
                  perView: 1,
                  autoplay: 5000,
                  useDots: true,
                  useArrows: true,
                }}
              >
                {data.photos
                  .filter((p, i) => i % 2 === 0)
                  .map((p, i) => {
                    const link = data.actions[i] ? data.actions[i].link : ''
                    return (
                      <a href={`${link ? link : '/akcii'}`} target="blank">
                        <img src={p.publicUrl} alt="hero-slider" />
                      </a>
                    )
                  })}
              </Carousel>
            )
          }
        />
        <Media
          query="(max-width: 768px)"
          render={() =>
            !isEmpty(data) && (
              <Carousel
                element="Hero-Carousel"
                options={{
                  type: 'carousel',
                  perView: 1,
                  autoplay: 5000,
                  useDots: true,
                  useArrows: true,
                }}
              >
                {data.photos
                  .filter((p, i) => i % 2 !== 0)
                  .map((p, i) => {
                    const links = data.links.split(',')
                    return (
                      <a href={`${links[i]}`} target="blank">
                        <img src={p.publicUrl} alt="hero-slider" />
                      </a>
                    )
                  })}
              </Carousel>
            )
          }
        />

        {/* <div className="Offer">
          <h1 className="Offer-Title">Рекламний слайдер послуг</h1>
          <p className="Offer-Description">Унікальна торгова пропозиція</p>
          <div className="Offer-Buttons">
            <Button
              theme="primary"
              title="Записатись на консультацію"
              type="navhashlink"
              path="#calc"
              size={48}
            />
            <Button
              theme="secondary"
              title="Каталог послуг"
              type="navhashlink"
              path="#calc"
            />
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default withRouter(Hero)
