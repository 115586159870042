import React, { useState } from 'react'
import './Partners.sass'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import Carousel from '../../ui/Carousel/Carousel'
import { useEffect } from 'react'
import getCollection from '../../utils/db/getCollection'
import { find, isEmpty, orderBy } from 'lodash'

function Partners() {
  const [partners, setPartners] = useState(null)

  useEffect(() => {
    getCollection({
      path: 'partners',
      docIdName: 'partnerId',
      whereQueries: [{ fieldPath: 'isDeleted', op: '!=', value: true }],
    }).then((docs) => setPartners(orderBy(docs, ['pos'], ['asc'])))
  }, [])

  return (
    <section id="partners" className="Partners">
      <div className="Partners-Container DefaultContainer">
        <SectionTitle title="Наші партнери" />
        {!isEmpty(partners) && (
          <Carousel
            element="Partners-Carousel"
            options={{
              type: 'carousel',
              gap: 24,
              perView: 6,
              hoverpause: true,
              autoplay: 5000,
              useDots: true,
              useArrows: true,
              breakpoints: {
                992: { perView: 5 },
                768: { perView: 3 },
                576: { perView: 2 },
              },
            }}
          >
            {partners.map((p) => (
              <PartnerTile
                key={p.partnerId}
                src={find(p.photos, ['isAvatar', true])}
                link={p.link}
              />
            ))}
          </Carousel>
        )}
      </div>
    </section>
  )
}

function PartnerTile({ src, link }) {
  return (
    <a href={link} target="blank" className="PartnerTile">
      {src ? <img src={src.publicUrl} /> : null}
    </a>
  )
}

export default Partners
