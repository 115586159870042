import React, { useContext } from 'react'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import RequestConsultation from '../../components/RequestConsultation/RequestConsultation'
import FeedsCarousel from '../../components/FeedsCarousel/FeedsCarousel'
import './Contacts.sass'
import parse from 'html-react-parser'
import { AuthContext } from '../../App'
import Map from '../../ui/Map/Map'
import { useEffect } from 'react'

function Contacts() {
  const { contacts } = useContext(AuthContext)

  return (
    <>
      <div className="ContactsPage">
        <div className="ContactsPage-Container DefaultContainer">
          <SectionTitle title={'Контакти'} />
          <div className="Contacts-Map">
            <div class="mapouter">
              <div class="gmap_canvas">
                <iframe
                  width="100%"
                  height="350"
                  id="gmap_canvas"
                  src="https://maps.google.com/maps?q=%D0%BC.%20%D0%9B%D1%8C%D0%B2%D1%96%D0%B2,%20%D0%B2%D1%83%D0%BB.%20%D0%AE%D1%80%D1%96%D1%8F%20%D0%A0%D1%83%D1%84%D0%B0,%2045%D0%B0&t=&z=13&ie=UTF8&iwloc=&output=embed"
                  frameborder="0"
                  scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                ></iframe>
              </div>
            </div>
          </div>
          <div class="Contacts-Text">{parse(contacts.text)}</div>
        </div>
      </div>
      <RequestConsultation />
      <FeedsCarousel />
    </>
  )
}

export default Contacts
