import React from 'react'
import Icon from '../../../ui/Icon/Icon'
import './EmailLink.sass'
import { useContext } from 'react'
import { AuthContext } from '../../../App'

function EmailLink() {
  const { contacts } = useContext(AuthContext)

  return (
    <div className="EmailLink">
      <Icon name="envelope" weight="solid" />
      <span>{contacts.email}</span>
    </div>
  )
}

export default EmailLink
