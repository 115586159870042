import React, { useRef } from 'react'
import './DropdownMenu.sass'
import Icon from '../../../ui/Icon/Icon'
import { usePopUp } from '../../../hooks/usePopUp'

function DropdownMenu({ title, content }) {
  const pRef = useRef()
  const cRef = useRef()

  const [menuIsOpen, setMenuIsOpen] = usePopUp(pRef, cRef)

  return (
    <div className={`DropdownMenu ${menuIsOpen && 'Spoiler_status_expanded'}`}>
      <span ref={pRef} className="DropdownMenu-Title Site-NavLink">
        {title} <Icon name="angle-down" />
      </span>
      <div
        ref={cRef}
        className="DropdownMenu-Container"
        onClick={() => setMenuIsOpen(false)}
      >
        {content}
      </div>
    </div>
  )
}

export default DropdownMenu
