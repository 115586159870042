import React from 'react'
import './Footer.sass'
import Logo from '../../ui/Logo/Logo'
import SocialLinks from '../Top/SocialLinks/SocialLinks'
import PhoneLinks from '../Top/PhoneLinks/PhoneLinks'
import EmailLink from '../Top/EmailLink/EmailLink'
import { NavLink } from 'react-router-dom'
import Icon from '../../ui/Icon/Icon'
import { NavHashLink } from 'react-router-hash-link'
import scrollWithOffset from '../../ui/ScrollToTop/scrollWithOffset'

function Footer() {
  return (
    <footer id="contacts" className="Footer">
      <div className="Main DefaultContainer">
        <Logo />
        <div className="ContactBlock">
          <EmailLink />
          <PhoneLinks />
          <SocialLinks />
        </div>
      </div>
      <div className="Line" />
      <div className="Footer-InfoContainer DefaultContainer">
        <div className="Clinic">
          <div className="LinkBlock">
            <NavLink to="/laser-vision-correction">Лазерне лікування</NavLink>
            <NavLink to="/services">Прайс</NavLink>
            <NavHashLink
              to="/#doctors"
              smooth
              scroll={(el) => scrollWithOffset(el, -64)}
            >
              Колектив
            </NavHashLink>
            <NavHashLink
              to="/#feeds"
              smooth
              scroll={(el) => scrollWithOffset(el, -64)}
            >
              Відгуки
            </NavHashLink>
          </div>
          <div className="LinkBlock">
            <NavLink to="/about-optics">Оптика</NavLink>
            <NavLink to="/scientific-activity">Наукова діяльність</NavLink>
            <NavLink to="/osvitni-programi">Освітні програми</NavLink>
            <NavLink to="/mass-media">ЗМІ</NavLink>
          </div>
          <div className="LinkBlock">
            <a
              href="https://firebasestorage.googleapis.com/v0/b/pavliv-2c3a3.appspot.com/o/pdf%2Floyalty.pdf?alt=media"
              target="blank"
            >
              Програма лояльності “Екосистема здоров’я”
            </a>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/pavliv-2c3a3.appspot.com/o/pdf%2Fpolicy.pdf?alt=media"
              target="blank"
            >
              Договір публічної оферти
            </a>
            <NavLink to="/akcii">Акції</NavLink>
          </div>
        </div>
        <div className="Contacts">
          <div className="Address">
            <Icon name="map-marker-alt" weight="solid" />
            <p>
              м. Львів, вул. Юрія Руфа, 45а,
              <br />
              Проспект Червоної калини, 66а
            </p>
          </div>
          <div className="BusyHours">
            <p>Понеділок - П'ятиця: 10:00 - 18:00</p>
            <p>Субота: 10:00 - 16:00</p>
            <p>Неділя: вихідний</p>
          </div>
        </div>
      </div>
      <div className="Copyrights DefaultContainer">
        © 2008-2023 Всі права захищено. Самолікування може бути шкідливим для
        вашого здоров'я
      </div>
    </footer>
  )
}

export default Footer
