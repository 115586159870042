import React, { useEffect, useState } from 'react'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import parse from 'html-react-parser'
import { find, isEmpty } from 'lodash'
import Spinner from '../../ui/Spinner/Spinner'
import { withRouter, NavLink } from 'react-router-dom'
import getDoc from '../../utils/db/getDoc'
import './EduPage.sass'
import Icon from '../../ui/Icon/Icon'

function EduPage({ title, parentPath, docPath, ...router }) {
  const [data, setData] = useState(null)
  const id = router.match.params.id

  useEffect(() => {
    getDoc({ path: docPath, docId: id, docIdName: 'id' }).then((res) =>
      setData(res)
    )
  }, [])

  const avatar =
    data && !isEmpty(data.photos)
      ? find(data.photos, ['isAvatar', true]).publicUrl
      : null

  return (
    <div className="EduPage">
      <div className="Content DefaultContainer">
        {!isEmpty(data) ? (
          <>
            <div className="BreadCrumbs">
              <span>
                <NavLink
                  activeClassName="BreadCrumbs_Link_active"
                  exact
                  to={'/osvitni-programi'}
                >
                  Освітні програми
                </NavLink>
              </span>
              <span>
                <Icon name="angle-right" />
              </span>
              <span>
                <NavLink
                  activeClassName="BreadCrumbs_Link_active"
                  exact
                  to={parentPath}
                >
                  {title}
                </NavLink>
              </span>
              <span>
                <Icon name="angle-right" />
              </span>
              <span>
                <NavLink
                  className={'BreadCrumbs_Link_active'}
                  to={`${parentPath}/${data.id}`}
                >
                  {data.title}
                </NavLink>
              </span>
            </div>
            <img className="Avatar" src={avatar} alt={data.title} />
            <SectionTitle title={data.title} />
            <div className="EventDate">Дата проведення: {data.date}</div>
            <div className="Content">{parse(data.text)}</div>
          </>
        ) : (
          <Spinner type="module" />
        )}
      </div>
    </div>
  )
}

export default withRouter(EduPage)
