import React, { useContext } from 'react'
import InfoLine from '../../ui/InfoLine/InfoLine'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import './About.sass'
import aboutImg from './about.jpg'
import Button from '../../ui/Button/Button'
import ReactPlayer from 'react-player'
import light from './light.png'
import bgImage from './consultPattern.svg'
import { useEffect } from 'react'
import getDoc from '../../utils/db/getDoc'
import { useState } from 'react'
import { isEmpty } from 'lodash'
import parse from 'html-react-parser'
import { AuthContext } from '../../App'

function About() {
  const [data, setData] = useState(null)
  const { contacts, setShowContactUs } = useContext(AuthContext)
  console.log('🚀 ~ file: About.jsx:20 ~ About ~ contacts:', contacts)
  useEffect(() => {
    getDoc({
      path: 'pages',
      docId: 'about',
      docIdName: 'aboutPage',
    }).then((res) => setData(res))
  }, [])

  // const benefits = [
  //   'Прийом офтальмолога: дитячого та дорослого',
  //   'Комплексний та ретельний підхід до діагностики і лікування, зосереджуємось на профілактиці та лікуванні захворювань очей на ранніх стадіях',
  //   'Прийом профільних спеціалістів (кандидати медичних наук та лікарі вищої категоріїї: ендокринолог, ревматолог, невролог, доктор медичних наук, лікар вищої категорії імунолог - алерголог)',
  //   'Виїзд лікарів до пацієнта',
  //   'Широка технічна база сертифікованого медичного обладнання',
  //   'Спеціалізація на діагностиці та лікуванні захворювання «Сухе око»',
  //   'Ексклюзивно в західному регіоні України, проводимо «гігієну зору»: процедури для очей «Блефастім», «Брео», масаж повік',
  //   'Обстежуємо вії на демодекс',
  //   'Підбираємо нічні контактні лінзи, (ортокератологічні), які призупиняють прогресування близорукості (міопії) у дітей',
  //   'Власна оптика, яка дозволяє, не виходячи з клініки, придбати окуляри, контактні лінзи (денного та нічного користування), засоби догляду за ними',
  //   'Програма лояльності',
  // ]
  return (
    <section
      id="about"
      name="about"
      class="About"
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      <div className="DefaultContainer">
        <SectionTitle title="Про клініку" />
        {!isEmpty(data) && (
          <div class="About-Container">
            <div className="AboutVideo">
              <ReactPlayer
                url={data.video.publicUrl}
                width="100%"
                height="100%"
                controls
                // light={light}
                // light={
                //   find(data.photos, ['isTeaser', true]) &&
                //   find(data.photos, ['isTeaser', true]).publicUrl
                // }
              />
            </div>

            <div class="About-Text">
              {parse(data.text)}
              {/* <p>
                Клініка зору «Павлів» - це комплексний підхід до вирішення
                проблем iз зором, наша спеціалізація - сучасна діагностика і
                лікування з використанням новітнього обладнання досвідченими
                спеціалістами.
              </p>
              <p>
                Клініка заснована династією офтальмологів Павлів. Однією з наших
                найбільших переваг є 30–ти річний досвід офтальмологічної
                практики, який базується на міжнародних протоколах та стандартах
                лікування.
              </p>
              <p>
                Клініка зору «Павлів» об’єднала кращих практикуючих лікарів, які
                постійно підвищують свою кваліфікацію, отримують нові знання,
                приймаючи активну участь у міжнародних конференціях та форумах.
                Головний лікар клініки - кандидат медичних наук, головний
                офтальмолог Львівської області, офтальмохірург вищої категорії,
                провідний спеціаліст України з лікування захворювання «Cyxe
                Oко», членкиня офтальмологів Украіни та Європейського товариства
                катарактальних та рефракційних хірургів - Павлів Олександра
                Борисівна.
              </p>
              <p>
                Обираючи нас, Ви отримаєте професійне та сучасне вирішення
                проблем із зором, повноцінне лікування Ваших очей та організму в
                цілому.
              </p>
              <b>Серед переваг Клініки зору «Павлів»:</b>
              <div class="BenefitsList">
                {benefits.map((b) => (
                  <InfoLine icon="check" content={b} />
                ))}
              </div>
              <b>
                Разом повернемо Вам радість чіткого зору та повноцінне здорове
                життя! До зустрічі в «Клініці зору Павлів».
              </b> */}
              <Button
                theme="primary-white"
                title="Записатись на консультацію"
                type="button"
                onClick={() =>
                  setShowContactUs({
                    show: true,
                    title: 'Записатись на консультацію',
                    emailSubject: 'Запит на консультацію',
                  })
                }
                // onClick={() => (window.location.href = `tel:${contacts.phone}`)}
              />
            </div>
          </div>
        )}
      </div>
    </section>
  )
}

export default About
