import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/functions'

const firebaseConfig = {
  apiKey: 'AIzaSyAnpNgy3OzVi9RGqOCNylxmOdwvyT30g20',
  authDomain: 'pavliv-2c3a3.firebaseapp.com',
  projectId: 'pavliv-2c3a3',
  storageBucket: 'pavliv-2c3a3.appspot.com',
  messagingSenderId: '232278490170',
  appId: '1:232278490170:web:de0a5da661c3ecdcb24903',
}

firebase.initializeApp(firebaseConfig)

const firebaseSideEffect = firebase.initializeApp(
  firebaseConfig,
  'Side Effect instance'
)

const db = firebase.firestore()
const storageRef = firebase.storage().ref()
const { storage } = firebase
const functions = firebase.app().functions('europe-west3')

export { db, storage, storageRef, firebase, firebaseSideEffect, functions }
