import React from 'react'
import './FeedTile.sass'
import Button from '../../../ui/Button/Button'
import Icon from '../../../ui/Icon/Icon'
import { formatDateAsDayMonthNameYear } from '../../../utils/date/dateFormat'
import parse from 'html-react-parser'
import { isEmpty } from 'lodash'
import ReactPlayer from 'react-player'

function FeedTile({ key, author, created, video, service }) {
  return (
    <div key={key} className="FeedTile">
      <div className="Feed-Author">
        <span className="UserName">{author}</span>
        <span className="Date Caption-Medium">
          {formatDateAsDayMonthNameYear(created)}
        </span>
      </div>
      {!isEmpty(video) && (
        <div className="Feed-Video">
          <ReactPlayer url={video} width="100%" height="100%" controls />
        </div>
      )}

      {/* <div className="Feed-Text">{parse(text)}</div> */}
      {/* <div className="Feed-Controlls">
        <div className="ControllButton VideoButton">
          <Icon name="play" weight="solid" style={{ marginRight: '8px' }} />
          <span>Відео відгук</span>
        </div>
        <div className="ControllButton DetailButton">
          <span>Детальніше</span>
          <Icon
            name="angle-right"
            weight="solid"
            style={{ fontSize: '16px', marginLeft: '8px', marginTop: '2px' }}
          />
        </div>
      </div> */}
      {service && (
        <div className="Feed-Service">
          <div className="Tag">{service}</div>
        </div>
      )}
    </div>
  )
}

export default FeedTile
