import React, { useContext, useEffect } from 'react'
import './News.sass'
import askImg from './ask.jpg'
import Button from '../../ui/Button/Button'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import { useState } from 'react'
import getCollection from '../../utils/db/getCollection'
import { formatDateAsDayMontYearHHMM } from '../../utils/date/dateFormat'
import { find, isEmpty, orderBy } from 'lodash'
import parse from 'html-react-parser'
import { NavLink } from 'react-router-dom'
import Spinner from '../../ui/Spinner/Spinner'
import { AuthContext } from '../../App'

function News() {
  const { setShowContactUs } = useContext(AuthContext)
  const [news, setNews] = useState(null)

  useEffect(() => {
    getCollection({ path: 'news', docIdName: 'newsId' }).then((data) =>
      setNews(
        orderBy(
          data.filter((d) => !d.isDeleted && d.status === 'published'),
          ['created'],
          ['desc']
        )
      )
    )
  }, [])

  return (
    <div id="novyny" className="News">
      <div className="DefaultContainer">
        <SectionTitle title="Блог здоров'я" />
        <div className="News-Container">
          {!isEmpty(news) ? (
            <>
              <div className="TopNews">
                {news.slice(0, 3).map((n, i) => (
                  <NewsTile type={i === 0 ? 'BigTopType' : 'TopType'} {...n} />
                ))}
              </div>
              <div className="OtherNews">
                <p className="SubTitle">Інше</p>
                {news
                  .slice(3)
                  .filter((n, i) => i < 4)
                  .map((n) => (
                    <NewsTile type={'DefaultType'} {...n} />
                  ))}
                <Button
                  theme="primary-white"
                  title="Усі новини"
                  type="navlink"
                  path="/novyny/archiv"
                  // onClick={() => {
                  //   // window.scrollTo({ top: 0, behavior: 'smooth' })
                  //   setShowContactUs({
                  //     show: true,
                  //     title: 'Задати питання',
                  //     emailSubject:
                  //       '[Питання] Новий запит від користувача сайту',
                  //   })
                  // }}
                />
              </div>
            </>
          ) : (
            <Spinner type="module" />
          )}

          <div className="AskBlock">
            <img className="AskImg" src={askImg} alt="Задати питання" />
            <p className="SubTitle">Задати питання</p>
            <p className="Body-Regular-1">
              Наші спеціалісти з радістю дадуть відповідь на Ваші запитання
            </p>
            <div className="Buttons">
              <Button
                theme="primary-white"
                title="Задати питання"
                type="button"
                icon="envelope"
                iconWeight="solid"
                iconPosition="right"
                onClick={() => {
                  // window.scrollTo({ top: 0, behavior: 'smooth' })
                  setShowContactUs({
                    show: true,
                    title: 'Задати питання',
                    emailSubject: '[Питання] Новий запит від користувача сайту',
                  })
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export function NewsTile({
  newsId,
  type,
  title,
  photos,
  shortNews = '',
  longNews = '',
  created,
}) {
  const avatar = find(photos, ['isAvatar', true])

  return (
    <div className={`NewsTile ${type}`}>
      <NavLink to={`/novyny/${newsId}`}>
        {avatar && (
          <div className="NewsTile-Image">
            <img src={avatar.publicUrl} alt="title" />
          </div>
        )}
        <div className="NewsTile-Content">
          <h3 className="Title">{title}</h3>
          <p className="Caption-Regular Date">
            {created && formatDateAsDayMontYearHHMM(created)}
          </p>
          <p className="Body-Regular-1 NewsText">{parse(shortNews)}</p>
        </div>
      </NavLink>
    </div>
  )
}

export default News
