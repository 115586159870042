import React from 'react'
import './SocialLinks.sass'
import Icon from '../../../ui/Icon/Icon'
import { Link } from 'react-router-dom'

function SocialLinks() {
  return (
    <div className="SocialLinks">
      <span className="Title">Ми у мережах:</span>
      <a href="https://www.facebook.com/klinikapavliv" target="blank">
        <Icon name="facebook-f" weight="brand" />
      </a>
      <a href="https://www.instagram.com/klinikapavliv" target="blank">
        <Icon name="instagram" weight="brand" />
      </a>
    </div>
  )
}

export default SocialLinks
