import React from 'react'
import './Services.sass'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import { useState } from 'react'
import { useEffect } from 'react'
import promiseAllValues from '../../utils/promiseAllValues'
import getCollection from '../../utils/db/getCollection'
import { cloneDeep, isEmpty, orderBy } from 'lodash'
import Icon from '../../ui/Icon/Icon'
import ReactPlayer from 'react-player'
import scrollWithOffset from '../../ui/ScrollToTop/scrollWithOffset'
import { NavHashLink } from 'react-router-hash-link'
import RequestConsultation from '../../components/RequestConsultation/RequestConsultation'
import FeedsCarousel from '../../components/FeedsCarousel/FeedsCarousel'

function Services({ ...router }) {
  const el = router.location.hash ? router.location.hash.split('-') : null
  const [services, setServices] = useState(null)

  useEffect(() => {
    promiseAllValues({
      categories: getCollection({
        path: 'settings/price/categories',
        docIdName: 'categoryId',
        whereQueries: [{ fieldPath: 'isDeleted', op: '!=', value: true }],
      }),
      services: getCollection({
        path: 'settings/price/services',
        docIdName: 'serviceId',
        whereQueries: [{ fieldPath: 'isDeleted', op: '!=', value: true }],
      }),
    }).then((result) => {
      const cats = orderBy(result.categories, ['pos'], ['asc'])
      cats.forEach((c, i) => {
        if (i === 0 && isEmpty(el)) {
          c.isActive = true
        }

        if (!isEmpty(el) && el[1] === c.categoryId) {
          c.isActive = true
        }

        c.services = orderBy(
          result.services.filter((s) => s.categoryId === c.categoryId),
          ['pos'],
          ['asc']
        )
      })
      setServices(cats)
    })
  }, [])

  const setActiveService = (serviceId) => {
    const clone = cloneDeep(services)
    setServices(
      clone.map((c) => {
        c.isActive =
          c.categoryId === serviceId ? (c.isActive ? false : true) : false
        return c
      })
    )
  }

  return (
    <>
      <div className="Services">
        <div className="Services-Container DefaultContainer">
          <SectionTitle title="Прайс" />
          <div className="Price-Container">
            {!isEmpty(services) &&
              services.map((s) => (
                <ServiceTile
                  key={s.categoryId}
                  {...s}
                  onClick={setActiveService}
                />
              ))}
          </div>
        </div>
      </div>
      <RequestConsultation />
      <FeedsCarousel />
    </>
  )
}

function ServiceTile({
  isActive,
  categoryId,
  video,
  title,
  onClick,
  services,
}) {
  return (
    <div
      id={`category-${categoryId}`}
      className={`ServiceTile ${isActive && 'ServiceTile_theme_active'}`}
    >
      <NavHashLink
        to={`#category-${categoryId}`}
        smooth
        activeClassName="Site-NavLink_theme_active"
        scroll={(el) => scrollWithOffset(el, -144)}
      >
        <div className="Tile-Head" onClick={() => onClick(categoryId)}>
          <Icon name="angle-right" weight="solid" />
          <span className="Title">{title}</span>
        </div>
      </NavHashLink>

      <div className="Tile-Body">
        {video && (
          <div className="Video">
            <ReactPlayer
              url={video.publicUrl}
              width="100%"
              height="100%"
              controls
            />
          </div>
        )}
        <div className="PriceTable">
          <table>
            <thead>
              <tr>
                <th width="200px">Тривалість</th>
                <th>Назва послуги</th>
                <th className="Price">Вартість</th>
              </tr>
            </thead>
            <tbody>
              {services.map((s) => (
                <tr key={s.serviceId}>
                  <td>{s.duration} хв</td>
                  <td>{s.title}</td>
                  <td className="Price">{s.price} грн</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Services
