import React from 'react'
import { useContext } from 'react'
import { AuthContext } from '../../App'
import { useState } from 'react'
import { createForm } from '../../utils/newforms/createForm'
import StatusMessage, {
  useStatusMessage,
} from '../../ui/StatusMessage/StatusMessage'
import isFormValid from '../../utils/newforms/validation/isFormValid'
import getFormValues from '../../utils/newforms/getFormValues'
import pattern from './assets/consultPattern.svg'
import { functions } from '../../config/firebase'
import FormRender from '../../utils/newforms/render/FormRender'
import Button from '../../ui/Button/Button'
import getFieldRenderObject from '../../utils/newforms/render/getFieldRenderObject'
import './RequestConsultation.sass'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'

function RequestConsultation() {
  const { city } = useContext(AuthContext)
  const [form, setForm] = useState(
    createForm({
      formPattern: new ConsultForm(),
    })
  )
  const [isLoading, setIsLoading] = useState(false)
  const [showErrors, setShowErrors] = useState(false)
  const [statusMessage, setStatusMessage] = useStatusMessage()

  const onSubmit = async (e) => {
    e.preventDefault()
    if (!isFormValid({ form })) {
      return setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Будь ласка, заповніть усі поля форми',
        closeAfter: 5000,
      })
    }
    setIsLoading(true)

    try {
      window.ym(86916243, 'reachGoal', 'form')
    } catch (e) {
      console.log(e)
    }

    const values = getFormValues({ form })

    const func = functions.httpsCallable('emailSender')
    const text = `
      Ім'я клієнта: ${values.userName}<br/>
      Телефон: ${values.phone.value}<br/>
    `

    // sendSms({ message: text })

    func({
      subject: 'Запит на консультацію',
      to: 'klinikazoru19@gmail.com',
      html: text,
    }).then(({ data }) => {
      if (data === 'success') {
        setStatusMessage({
          show: true,
          type: 'success',
          message:
            'Ми отримали Ваш запит. Наші спеціалісти звяжуться з Вами найближчим часом',
          closeAfter: 5000,
        })
        setForm(createForm({ formPattern: new ConsultForm() }))
        return setIsLoading(false)
      }
    })
  }

  return (
    <div className="RequestConsultation">
      <div
        className="RequestConsultation-Container DefaultContainer Pattern"
        style={{ backgroundImage: `url(${pattern})` }}
      >
        <SectionTitle title="Записатись на первинний огляд" />
        {statusMessage.show && (
          <StatusMessage
            className="Site-StatusMessage"
            type={statusMessage.type}
            message={statusMessage.message}
          />
        )}
        <div className="RequestConsultation-Form">
          <FormRender
            sections={[{ fields: ['userName', 'phone'] }]}
            form={form}
            setForm={setForm}
            errors={showErrors}
          />
          <Button
            theme="primary-white"
            title="Записатись на консультацію"
            isLoading={isLoading}
            onClick={onSubmit}
          />
        </div>
        <p className="Caption-Medium">
          Надсилаючи цей запит Ви погоджуєтесь з нашою{' '}
          <a
            href="https://firebasestorage.googleapis.com/v0/b/pavliv-2c3a3.appspot.com/o/pdf%2Fpersonal.pdf?alt=media"
            target="blank"
          >
            політикою обробки персональних даних.
          </a>
        </p>
      </div>
    </div>
  )
}

class ConsultForm {
  constructor() {
    this.userName = {
      field: {
        fieldId: 'userName',
        fieldType: 'input',
        inputType: 'text',
        placeholder: "Ваше ім'я",
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.phone = {
      field: {
        fieldId: 'phone',
        fieldType: 'phone',
        inputType: 'text',
        placeholder: 'Телефон',
        required: true,
      },
      render: getFieldRenderObject(),
    }
  }
}

export default RequestConsultation
