import React, { useRef } from 'react'
import './MainServices.sass'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import { useState } from 'react'
import { useEffect } from 'react'
import getCollection from '../../utils/db/getCollection'
import { cloneDeep, find, isEmpty, orderBy } from 'lodash'
import Button from '../../ui/Button/Button'
import parse from 'html-react-parser'
import bgImage from './consultPattern.svg'
import scrollWithOffset from '../../ui/ScrollToTop/scrollWithOffset'

function MainServices() {
  const [services, setServices] = useState(null)
  const el = useRef()
  useEffect(() => {
    getCollection({
      path: 'settings/price/categories',
      docIdName: 'categoryId',
      whereQueries: [{ fieldPath: 'isDeleted', op: '!=', value: true }],
    }).then((docs) => {
      const withPos = orderBy(docs, ['pos'], ['asc'])
      withPos[0].isActive = true
      setServices(withPos)
    })
  }, [])

  const setActiveService = (serviceId) => {
    const clone = cloneDeep(services)
    setServices(
      clone.map((c) => {
        c.isActive = c.categoryId === serviceId
        return c
      })
    )
    scrollWithOffset(el.current, -80)
  }

  const activeService = !isEmpty(services)
    ? find(services, ['isActive', true])
    : null

  return (
    <section
      id="services"
      className="MainServices"
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      <div className="MainServices-Container DefaultContainer">
        <SectionTitle title="Головні послуги" />
        <div className="Services-Cloud">
          {!isEmpty(services) &&
            services.map((s) => (
              <div
                key={s.categoryId}
                className={`CloudTile ${
                  s.isActive ? 'CloudTile_theme_active' : ''
                }`}
                onClick={() => setActiveService(s.categoryId)}
              >
                {s.title}
              </div>
            ))}
        </div>
        {activeService && (
          <>
            <div ref={el} className="ActiveService-Container">
              <div className="Title">{activeService.title}</div>
              <div className="Description">
                {activeService.description
                  ? parse(activeService.description)
                  : 'Немає опису'}
              </div>
            </div>
            <div className="Services-Button">
              <Button
                theme="primary-white"
                title="Перейти до всіх послуг"
                type="navlink"
                path={`/services#category-${activeService.categoryId}`}
              />
            </div>
          </>
        )}
      </div>
    </section>
  )
}

export default MainServices
